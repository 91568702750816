import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';

import Head from "../components/Head"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"

import nati from "../images/nati.png"

import "../styles/reset.scss"
import "../styles/main.sass"

const Home = () => (
  <ParallaxProvider>
    <div className='home'>
      <Head />
      <Navigation />
      <div className="wrapper">
        <section className="intro">
          <Parallax y={[-50, 50]}>
            <h1>I'm Nati,<br />iOS Developer</h1>
          </Parallax>
          <Parallax y={[30, -30]} x={[-3, 3]}>
            <p className="interests">Dedicated and experienced software developer interested in mobile and frontend technologies.</p>
          </Parallax>
          <img className='img-nati' src={nati} alt="nati"/>
          <Parallax y={[30, -30]} x={[3, -3]}>
            <p className="currently">Currently working at ForeFlight helping pilots making flight planning easier.</p>
          </Parallax>
        </section>
        <section className="what">
          <Parallax x={[-5, 5]}>
            <h1>Profile?</h1>
          </Parallax>
          <Parallax y={[30, -30]} x={[3, -3]}>
            <p>I am a trouble-shooter, great challenges motivate me and I have a strong desire to make things right.<br/> Optimist and positive person who is always hoping for the best. My high spirits seem to rub off on others who enjoy working with me and I frequently adopt the belief that the best will happen.<br/>I believe that starting is halfway to success. I'm proactive and results-oriented, always eager to begin projects and tasks promptly. I get impatient when there are delays, as I value taking action in the present to accomplish what we can. <br/>I enjoy goals and competitions, if someone says something can't be done, then I will set my mind on proving them wrong.</p>
          </Parallax>
        </section>
        <section className="how">
          <Parallax x={[10, -10]}>
            <h1>Freelance?</h1>
          </Parallax>
          <Parallax y={[30, -30]} x={[-3, 3]}>
            <p>I have a full time job but I like to have one or two side projects to continue learning different things and feel fresh.</p>
          </Parallax>
        </section>
        <section className="contact">
          <Parallax x={[-10, 10]}>
            <h1>Get in touch</h1>
          </Parallax>
          <p>I speak Spanish, English, Danish and a bit of French.</p>
          <a className="email" href="mailto: hola@nanati.com">hola@nanati.com</a>
        </section>
        <Footer />
      </div>
    </div>
  </ParallaxProvider>
)

export default Home